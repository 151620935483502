// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable complexity */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-call */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable promise/catch-or-return */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable promise/always-return */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-floating-promises */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable unicorn/consistent-function-scoping */
import GbButton from '@nc-gb-sds/button/dist/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// declare let window: any;

export const PostCallAssistent: React.FC = () => {
    const rootClassName = 'post-call-assistent';
    const [showContactForm, setShowContactForm] = useState(false);
    const [startSchedule, setStartSchedule] = useState(false);
    const [startAgain, setStartAgain] = useState(false);
    const [isScheduele, setIsScheduele] = useState(false);

    const history = useHistory();

    // window.OneSignal = window.OneSignal || [];
    // const { OneSignal } = window;

    navigator.serviceWorker.register('sw.js');

    // const prepareOneSignal = () => {
    //     OneSignal.push(() => {
    //         OneSignal.init(
    //             {
    //                 appId: '43db7a90-f338-46f1-90d4-2ce575c5be3e',
    //                 promptOptions: {
    //                     slidedown: {
    //                         enabled: true,
    //                         actionMessage: 'In order to be called back, please allow notifications',
    //                         acceptButtonText: 'ok',
    //                     },
    //                 },
    //                 welcomeNotification: {
    //                     title: 'Jupiter',
    //                     message: 'Now you will be notified when someone is calling you',
    //                 },
    //             },
    //             // Automatically subscribe to the new_app_version tag
    //             OneSignal.sendTag('new_app_version', 'new_app_version', (tagsSent: any) => {
    //             // Callback called when tag has finished sending
    //                 // eslint-disable-next-line no-console
    //                 console.log('new_app_version TAG SENT', tagsSent);
    //             }),
    //         );
    //     });
    // };

    // function notifyMe() {
    //     // Verifica se o browser suporta notificações
    //     if (!('Notification' in window)) {
    //         // eslint-disable-next-line no-alert
    //         alert('This browser doestn support notifications');
    //     } else if (Notification.permission === 'granted') {
    //         // If it's okay let's create a notification
    //         const notification = new Notification('Hi there!');
    //     } else if (Notification.permission !== 'denied') {
    //         Notification.requestPermission((permission) => {
    //         // If the user accepts, let's create a notification
    //             if (permission === 'granted') {
    //                 const notification = new Notification('Hi there!');
    //             }
    //         });
    //     }

    //     // At last, if the user has denied notifications, and you
    //     // want to be respectful there is no need to bother them any more.
    // }

    // eslint-disable-next-line no-console
    console.log(startAgain, startSchedule);
    const startAsap = (): void => {
        setShowContactForm(true);
        // prepareOneSignal();
        history.push('/user/post-call/contact-form');
    };
    const startScheduleHandler = (): void => {
        setStartSchedule(false);
        // prepareOneSignal();
        setIsScheduele(true);
        history.push('/user/post-call/schedule');
    };
    const startCallAgainHandler = (): void => {
        setStartAgain(true);
        history.push('/user/call-venn');
    };
    return (
        <div className={rootClassName}>
            { !isScheduele
                && !showContactForm
                && (
                    <>
                        <div className={`${rootClassName}__top`}>
                            <h4>When would you wish to be called back?</h4>
                        </div>
                        <div className={`${rootClassName}__bottom`}>
                            <GbButton
                                onClick={() => startAsap()}>
                                ASAP
                            </GbButton>
                            <GbButton
                                onClick={() => startScheduleHandler()}>
                                Schedule
                            </GbButton>
                            <GbButton
                                className={`${rootClassName}__bottom__other`}
                                onClick={() => startCallAgainHandler()}>
                                Call Again
                            </GbButton>
                        </div>
                    </>
                ) }
            { /* { isScheduele && <Schedule /> }
            { showContactForm && !isScheduele && <ContactForm /> } */ }
        </div>
    );
};
