// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
// import audioURL from './ringtone.mp3';

interface myProps {
    onCallReceived: () => void,
    onHangup: () => void,
    caller: string | undefined
}

export const ReceivingCall: React.FC<myProps> = (props: myProps) => {
    const rootClassName = 'jp-receiving-call';
    const url = '/ringtone.mp3';
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(true);

    useEffect(() => {
        if (!audio) return;
        // audio.setAttribute('crossorigin', 'anonymous');
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
        // audio.setAttribute('crossorigin', 'anonymous');
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    // eslint-disable-next-line unicorn/consistent-function-scoping
    useEffect(() => () => audio.pause(), []);

    return (
        <div className={rootClassName}>
            <div className={`${rootClassName}__info`}>
                <span className={`${rootClassName}__info__status`}>Being called by</span>
                <span className={`${rootClassName}__info__domain`}>{ props.caller }</span>
            </div>
            <div className={`${rootClassName}__controls`}>
                <div
                    className={`${rootClassName}__controls__hangup`}
                    onClick={props.onHangup}
                    role="button"
                    tabIndex={0}
                    aria-hidden="true" />
                <div
                    className={`${rootClassName}__controls__pickup`}
                    onClick={props.onCallReceived}
                    role="button"
                    tabIndex={0}
                    aria-hidden="true" />
            </div>
        </div>
    );
};
