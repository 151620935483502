// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable complexity */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable unicorn/consistent-function-scoping */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
import GbButton from '@nc-gb-sds/button/dist/react';
import GbTag from '@nc-gb-sds/tag/dist/react';
import React, { useState } from 'react';
// import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
// import { Overlay } from './Overlay';
import { PopOver } from './PopOver';

interface myProps {
    location: any | null
}

export const PostCall:React.FC<myProps> = (props: myProps) => {
    const rootClassName = 'jp-post-call';
    const [callingReason] = useState<any | null>(props.location?.state?.reason ? props.location?.state.reason : 'New business');
    const [showPopOver, setShowPopOver] = useState(false);

    const history = useHistory();

    const goBackHandler = () => {
        history.push('/call');
    };

    const startPopOverHandler = () => {
        setShowPopOver(true);
    };

    // const domNodeForPortals = document.querySelector('.portal-overlay');

    return (
        <div className={rootClassName}>
            { /* { showPopOver && ReactDOM.render(<Overlay />, domNodeForPortals) } */ }
            { /* { !showPopOver && domNodeForPortals && ReactDOM.unmountComponentAtNode(domNodeForPortals) } */ }
            { !showPopOver
                && (
                    <>
                        <div className={`${rootClassName}__top`}>
                            <GbTag className={`${rootClassName}__top__reason-tag`}>{ callingReason }</GbTag>
                            <h4>Call has ended</h4>
                        </div>
                        <div className={`${rootClassName}__bottom`}>
                            <GbButton
                                onClick={() => startPopOverHandler()}>
                                Add Information
                            </GbButton>
                            <GbButton
                                className="dismiss"
                                onClick={() => goBackHandler()}>
                                Dismiss
                            </GbButton>
                        </div>
                    </>
                ) }
            { showPopOver && <PopOver /> }
        </div>
    );
};
