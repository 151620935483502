import GbTextfield from '@nc-gb-sds/textfield/dist/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// eslint-disable-next-line complexity
export const UserCalling: React.FC = () => {
    const rootClassName = 'jp-user-calling';
    const [googleLoader, setGoogleLoader] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [hasSearched] = useState(false);
    // const [isCalling, setIsCalling] = useState(false);
    // const [showVennPage] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const timer = setTimeout(() => {
            setGoogleLoader(false);
        }, 1500);
        return () => clearTimeout(timer);
    }, []);

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const searchFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setSearchValue(value);
    };

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const googleSearchHandler = (event: React.KeyboardEvent): void => {
        if (event.key === 'Enter') {
            history.push({
                pathname: '/user/google-search',
                state: { value: searchValue },
            });
            // setHasSearched(true);
        }
    };

    // // eslint-disable-next-line unicorn/consistent-function-scoping
    // const callVennHandler = (): void => {
    //     setIsCalling(true);
    // };

    // eslint-disable-next-line unicorn/consistent-function-scoping
    // const goToVennHandler = (): void => {
    //     setShowVennPage(true);
    // };

    return (
        <div className={rootClassName}>
            { googleLoader
            && (
                <div className={`${rootClassName}__loader`} />
            ) }
            { !googleLoader
            && !hasSearched
            && (
                <div className={`${rootClassName}__search`}>
                    <div className={`${rootClassName}__search__field`}>
                        <GbTextfield
                            aria-label="Search Input"
                            placeholder="Search or type URL"
                            onChange={searchFieldChangeHandler}
                            onKeyPress={googleSearchHandler} />
                    </div>
                </div>
            ) }
            { /* { hasSearched
            && !isCalling
            && !showVennPage
            && (
                <div className={`${rootClassName}__searched`}>
                    <div className={`${rootClassName}__searched__field-wrapper`}>
                        <div className={`${rootClassName}__searched__field-wrapper__input`}>
                            <GbTextfield
                                aria-label="Search Input"
                                placeholder="Search or type URL"
                                onChange={searchFieldChangeHandler}
                                value={searchValue} />
                        </div>
                    </div>
                    <div className={`${rootClassName}__searched__form`}>
                        <div className={`${rootClassName}__searched__form__button1`}>
                            <GbButton
                                onClick={() => callVennHandler()} />
                        </div>
                        <div className={`${rootClassName}__searched__form__button2`}>
                            <GbButton
                                onClick={() => goToVennHandler()} />
                        </div>
                    </div>
                    <div className={`${rootClassName}__searched__footer`} />
                </div>
            ) } */ }
            { /* { isCalling
            && (
                <Call />
            ) } */ }
            { /* { showVennPage
            && !isCalling
            && (
                <div className={`${rootClassName}__veen-page`}>
                    <div className={`${rootClassName}__veen-page__1`}>
                        <div className={`${rootClassName}__veen-page__1__button`}>
                            <GbButton
                                iconName="other"
                                onClick={() => callVennHandler()}>
                                Call
                            </GbButton>
                        </div>
                    </div>
                    <div className={`${rootClassName}__veen-page__2`} />
                    <div className={`${rootClassName}__veen-page__3`} />
                    <div className={`${rootClassName}__veen-page__4`} />
                </div>
            ) } */ }
        </div>
    );
};
