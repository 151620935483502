// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-len */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-expressions */
import GbButton from '@nc-gb-sds/button/dist/react';
import React, { useEffect, useRef, useState } from 'react';
import { AudioSettings } from './AudioSettings';

interface myProps {
    peerDomain: string | undefined,
    onHangUpCall: () => void,
    myStreamObject: MediaStream,
    setAudioDestination: (val: string) => void,
}

export const Calling: React.FC<myProps> = (props: myProps) => {
    const rootClassName = 'jp-calling';
    const url = '/phonesound.mp3';
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(true);
    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [volume, setVolume] = useState<number>(50);

    const popUpBoundaryRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!audio) return;
        if (!volume) return;
        audio.setAttribute('crossorigin', 'anonymous');
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
        audio.setAttribute('crossorigin', 'anonymous');
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    // eslint-disable-next-line unicorn/consistent-function-scoping
    useEffect(() => () => audio.pause(), []);

    const muteMic = (): void => {
        // eslint-disable-next-line no-param-reassign
        props.myStreamObject.getAudioTracks()[0].enabled = !(props.myStreamObject.getAudioTracks()[0].enabled);
        setIsMuted(!isMuted);
    };

    return (
        <div className={rootClassName}>
            <div className={`${rootClassName}__call-info`}>
                <span className={`${rootClassName}__call-info__status`}>Calling...</span>
                <span className={`${rootClassName}__call-info__domain`}>{ props.peerDomain }</span>
            </div>
            <div className={`${rootClassName}__footer__controls`} ref={popUpBoundaryRef}>
                <div className={`${rootClassName}__footer__controls__mic`}>
                    <GbButton
                        size="lg"
                        className={`${rootClassName}__footer__controls__mic__button ${isMuted ? 'muted' : ''}`}
                        skin="ghost"
                        skinModifier="inverted"
                        isIcon
                        iconName="mute"
                        onClick={() => muteMic()}>
                        Button
                    </GbButton>
                </div>
                <div
                    className={`${rootClassName}__footer__controls__phone`}
                    onClick={props.onHangUpCall}
                    role="button"
                    tabIndex={0}
                    aria-hidden="true">
                    <span className={`${rootClassName}__footer__controls__phone__icon`} />
                </div>
                <div className={`${rootClassName}__footer__controls__sound`}>
                    <AudioSettings changeAudioDestination={(value) => props.setAudioDestination(value)} changeSpeakersVolume={(value) => setVolume(value as number)} audioStream={props.myStreamObject} />
                </div>
            </div>
        </div>
    );
};
