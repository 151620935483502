// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable unicorn/consistent-function-scoping */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable guard-for-in */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import GbTextfield from '@nc-gb-sds/textfield/dist/react';
import GbButton from '@nc-gb-sds/button/dist/react';

interface myProps {
    onCallingDomain: (domain: string | undefined) => void,
    users: any,
    domainOnServer?: string,
}

export const HomeCall: React.FC<myProps> = (props: myProps) => {
    const rootClassName = 'jp-home-call';
    const [domain, setDomain] = useState<string>(props.domainOnServer ? props.domainOnServer : '');
    const [domainsInServer, setDomainsInServer] = useState<string[]>([]);
    const [showCallBtn, setShowCallBtn] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    function checkDomains() {
        setIsLoading(true);
        for (const value in props.users) {
            if (props.users[value] === window.location.hostname) return;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            setDomainsInServer((old) => [...old, props.users[value]]);
        }
    }

    useEffect(() => {
        checkDomains();
    }, [success]);

    useEffect(() => {
        if (success) {
            setShowCallBtn(true);
            setIsLoading(false);
            setShowSuccess(true);
        } else {
            setShowCallBtn(false);
            setIsLoading(false);
            setShowSuccess(false);
        }
        domainsInServer.forEach((el) => {
            if (el === domain) setSuccess(true);
            else {
                setSuccess(false);
            }
        });
    }, [isLoading]);

    const domainFiledChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setDomain(value);
        setIsLoading(true);
    };

    return (
        <div className={rootClassName}>
            <div className={`${rootClassName}__inputs`}>
                <GbTextfield
                    className={`${rootClassName}__inputs__domain-field`}
                    secondaryIcon={showSuccess ? { label: 'tick-circled-filled', name: 'tick-circled-filled' } : undefined}
                    isInverted
                    isLoading={isLoading}
                    placeholder="Type domain here"
                    aria-label="Domain Input"
                    skin={showSuccess ? 'success' : undefined}
                    onChange={domainFiledChangeHandler}
                    value={domain} />
                { showCallBtn
            && (
                <GbButton
                    skin="gray"
                    className={`${rootClassName}__inputs__call-btn`}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    onClick={() => props.onCallingDomain(domain)}>
                    Call Now
                </GbButton>
            ) }
            </div>
        </div>
    );
};
