import GbButton from '@nc-gb-sds/button/dist/react';
import React, { useState } from 'react';

export const CallEnded: React.FC = () => {
    const rootClassName = 'jp-call-ended';
    const [backToWebsite, setBackToWebsite] = useState(false);

    // eslint-disable-next-line no-console
    console.log(backToWebsite);
    return (
        <div className={rootClassName}>
            <h6>Your call has ended</h6>
            <h4>Thank you!</h4>
            <div className="end-circle">
                <div className="thumb" />
                <div className="image" />
            </div>
            <GbButton
                className="back"
                onClick={() => setBackToWebsite(true)}>
                Go back to website
            </GbButton>
        </div>
    );
};
