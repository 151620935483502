// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-len */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React, { useEffect, useRef, useState } from 'react';
import GbButton from '@nc-gb-sds/button/dist/react';
import { AudioVisualizer } from './AudioVisualizer';
import { AudioSettings } from './AudioSettings';
import { Timer } from './Timer';

interface myProps {
    onHangup: () => void,
    streamObject: MediaStream,
    myStreamObject: MediaStream,
    peerDomain: string | undefined,
    setAudioDestination: (val: string) => void,
}

export const InCall: React.FC<myProps> = (props: myProps) => {
    const popUpBoundaryRef = useRef<HTMLDivElement>(null);
    const rootClassName = 'jp-in-call';
    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [volume, setVolume] = useState<number>(80);

    const myVideoElement = useRef<HTMLVideoElement>(null);

    const muteMic = (): void => {
        // eslint-disable-next-line no-param-reassign
        props.myStreamObject.getAudioTracks()[0].enabled = !(props.myStreamObject.getAudioTracks()[0].enabled);
        setIsMuted(!isMuted);
    };

    useEffect(() => {
        if (myVideoElement.current) {
            myVideoElement.current.srcObject = null;
            try {
                myVideoElement.current.srcObject = props.myStreamObject;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
            // videoElement.current.srcObject = props.streamObject;
            // videoElement.current.muted = true;
            // videoElement.current.volume = 0;
        }
    }, []);

    return (
        <div className={rootClassName}>
            <div className={`${rootClassName}__call-info`} ref={popUpBoundaryRef}>
                <span className={`${rootClassName}__call-info__status`}>On Call with</span>
                <span className={`${rootClassName}__call-info__domain`}>{ props.peerDomain }</span>
            </div>
            <video className={`${rootClassName}__video-mine`} muted playsInline ref={myVideoElement} autoPlay>
                <track kind="captions" />
            </video>
            <div className="jp-visualizer">
                <AudioVisualizer streamObject={props.streamObject} gainVolume={volume} />
            </div>
            <div className={`${rootClassName}__footer`}>
                <div className={`${rootClassName}__footer__timer`}>
                    <Timer />
                </div>
                <div className={`${rootClassName}__footer__controls`}>
                    <div className={`${rootClassName}__footer__controls__mic`}>
                        <GbButton
                            size="lg"
                            className={`${rootClassName}__footer__controls__mic__button ${isMuted ? 'muted' : ''}`}
                            skin="ghost"
                            skinModifier="inverted"
                            isIcon
                            iconName="mute"
                            onClick={() => muteMic()}>
                            Button
                        </GbButton>
                    </div>
                    <div
                        className={`${rootClassName}__footer__controls__phone`}
                        onClick={props.onHangup}
                        role="button"
                        tabIndex={0}
                        aria-hidden="true">
                        <span className={`${rootClassName}__footer__controls__phone__icon`} />
                    </div>
                    <div className={`${rootClassName}__footer__controls__sound`}>
                        <AudioSettings changeAudioDestination={(value) => props.setAudioDestination(value)} changeSpeakersVolume={(value) => setVolume(value as number)} audioStream={props.myStreamObject} />
                    </div>
                </div>
            </div>
        </div>
    );
};
