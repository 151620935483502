import React from 'react';

export const Business:React.FC = () => {
    const rootClassName = 'jp-business-call';
    return (
        <div className={rootClassName}>
            <h1>Business Side</h1>
        </div>
    );
};
