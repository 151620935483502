// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-len */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable unicorn/consistent-function-scoping */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable complexity */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useRef, useState } from 'react';
// import GbTag from '@nc-gb-sds/tag/dist/react';
// import GbButton from '@nc-gb-sds/button/dist/react';
import io, { Socket } from 'socket.io-client';
import Peer, { SignalData } from 'simple-peer';
import { InCall } from 'src/views/userCalling/react';
import { ReceivingCall } from 'src/views/jupiterCall/react';
import { SOCKET_IO_SERVER } from './constants';
import { CallEnded } from './CallEnded';

export const ReceiveCall: React.FC = () => {
    const rootClassName = 'jp-jupiter-receive-call';
    const [startCall, setStartCall] = useState(false);
    const [callReceived, setCallReceived] = useState(false);
    const [postCall, setPostCall] = useState(false);
    const [isCalling, setIsCalling] = useState(false);
    const [callRejected, setCallRejected] = useState(false);
    const [myStream, setMyStream] = useState<MediaStream |null>(null);

    const [yourID, setYourID] = useState('');
    const [peerDomain, setPeerDomain] = useState<string | undefined>('');
    // const [deviceID, setDeviceID] = useState<string | undefined>('');
    const [domainOnServer, setDomainOnServer] = useState<string>();
    const [users, setUsers] = useState({});
    const [caller, setCaller] = useState('');
    const [callerSignal, setCallerSignal] = useState<SignalData>();
    const [peerStream, setPeerStream] = useState<MediaStream |null>(null);

    const socket = useRef<Socket>();

    const params = {
        initiator: true,
        trickle: false,
        stream: myStream || undefined,
        reconnectTimer: 100,
        /* config: {
            iceServers: [
                { urls: 'stun:global.stun.twilio.com:3478' },
                {
                    urls: 'stun:stun.l.google.com:19302',
                },
            ],
        }, */
    };

    const addStream = (id: string | undefined) => {
        // eslint-disable-next-line no-console
        console.log(peerDomain, domainOnServer, users, caller, callerSignal, isCalling);
        // if (myStream) {
        //     myStream.getTracks().forEach((track) => {
        //         track.stop();
        //     });
        // }
        // eslint-disable-next-line no-console
        // if (!deviceID) return;
        // ask the user for permissions
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        // eslint-disable-next-line promise/catch-or-return
        // eslint-disable-next-line no-void
        void navigator.mediaDevices.getUserMedia({
            video: false,
            audio: {
                echoCancellation: true,
                deviceId: id ? { exact: id } : undefined,
            },
        // eslint-disable-next-line promise/always-return
        }).then((streamObject) => {
            setMyStream(streamObject);
        });
    };

    function callPeer(domain: string | undefined) {
        if (!myStream) return;
        // myPeerObject.addStream(myStream);
        params.initiator = true;
        // params.trickle = false;
        params.stream = myStream;
        const peer = new Peer(params);
        // const peer = new Peer(params);
        // setMyPeerObject(null);
        // const peer = myPeerObject;
        // eslint-disable-next-line no-console
        if (!peer) return;

        // on creating a new peer the peer will try to signal
        // the peers will establish a connection that its called the handshake
        // one peer will send the signal to the other then
        // the other peer will accept that signal then
        // it will signal its own data back to the caller
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        // setAudioDevices((old) => [...old, { name: device.label, id: device.deviceId }]);
        // const theSenders: any = [];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        // myStream.getTracks().forEach((track) => theSenders.push(peer.addTrack(track, myStream)));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        // myStream.getTracks().forEach((track) => theSenders.push(track));
        // myPeerObject.on('track', (track: MediaStreamTrack, stream: MediaStream) => {
        //     // myPeerObject.addTrack(track);
        //     theSenders.push(track);
        // });
        // setSenders(theSenders);
        // and the hanshake its created
        peer.on('signal', (data: any) => {
            if (!socket.current || startCall) return;
            setIsCalling(true);
            socket.current.emit('callUser', { userToCall: domain, signalData: data, from: yourID });
        });

        // stream its the incomming data from the other peer
        peer.on('stream', (streamObject: React.SetStateAction<MediaStream | null>) => {
            setPeerStream(streamObject);
            // eslint-disable-next-line no-console
            console.log('The Other Stream', streamObject);
        });

        peer.on('close', () => {
            setStartCall(false);
            // setReceivingCall(false);
            peer.removeAllListeners();
            peer.destroy();
            setPostCall(true);
            window.location.reload();
        });

        if (!socket.current) return;
        // getting the return signal from the other peer in order to complete the handshake
        socket.current.on('callAccepted', (signal: SignalData) => {
            peer.signal(signal);
            setStartCall(true);
            setIsCalling(false);
        });

        if (!socket.current) return;
        socket.current.on('hangupCall', () => {
            setStartCall(false);
            peer.removeAllListeners();
            peer.destroy();
            setPostCall(true);
        });

        // eslint-disable-next-line no-console
        peer.on('error', (err: any) => { console.log('error', err); });
    }

    const startCallHandler = (): void => {
        callPeer('testjupiter2.ncuilab.com');
    };
    const hangUplHandler = (): void => {
        setStartCall(false);
        setPostCall(true);
    };

    const handleCallRejected = () => {
        setCallRejected(true);
    };

    const handleCallReceived = () => {
        setTimeout(() => {
            setCallReceived(true);
        }, 2000);
        startCallHandler();
    };

    useEffect(() => {
        const myDomain = window.location.hostname;
        // force to connect to the root url in the server that triggers the io.on event
        socket.current = io(SOCKET_IO_SERVER, {
            auth: {
                token: myDomain,
            },
        });
        addStream('');

        socket.current.on('yourID', (id: any) => {
            // eslint-disable-next-line no-console
            console.log('myID:', id);
            setYourID(id);
        });
        socket.current.on('allUsers', (usersObject: any) => {
            setUsers(usersObject);
        });

        socket.current.on('otherUser', (domain: string) => {
            // eslint-disable-next-line no-console
            console.log('otherDomain', domain);
            setDomainOnServer(domain);
        });

        // handling the fact that whe are being called
        // notifying that the person is being called
        socket.current.on('hey', (data: any) => {
            // eslint-disable-next-line no-console
            setPeerDomain(data?.from);
            setCaller(data?.from);
            setCallerSignal(data?.signal);
            // eslint-disable-next-line no-console
            console.log('The signal', data?.signal);
        });

        socket.current.on('hangupCall', () => {
            setPostCall(true);
            // window.location.reload();
        });

        socket.current.on('disconnectFromServer', () => {
            if (socket.current) {
                // socket.current.disconnect();
                setPostCall(true);
            }
        });
        // startCallHandler();
    }, []);

    return (
        <div className={rootClassName}>
            { !postCall && !callRejected && !callReceived && !peerStream && <ReceivingCall caller="vennfoods.com" onHangup={() => handleCallRejected()} onCallReceived={() => handleCallReceived()} /> }
            { !postCall && !callRejected && callReceived && peerStream && <InCall mediaStream={peerStream} onHangup={() => hangUplHandler()} /> }
            { !postCall && callRejected && <h1>Call Rejected</h1> }
            { postCall && <CallEnded /> }
        </div>
    );
};
