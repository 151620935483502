// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable complexity */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/no-static-element-interactions */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import GbTag from '@nc-gb-sds/tag/dist/react';
import GbButton from '@nc-gb-sds/button/dist/react';
import { ContactForm } from './ContactForm';
import { SchedueleButton } from './SchedueleButton';

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value + 1); // update the state to force render
}

export const Schedule: React.FC = () => {
    const rootClassName = 'jp-schedule';
    const [showContactForm, setShowContactForm] = useState(false);
    const [hours, setHours] = useState('');

    const forceUpdate = useForceUpdate();

    useEffect(() => {
        forceUpdate();
    }, []);

    const selectHandler = (hoursToReceive:string) => {
        setShowContactForm(true);
        setHours(hoursToReceive);
    };

    const dayTomorrow: number = new Date().getDate() + 1;

    return (
        <div className={rootClassName}>
            { !showContactForm
            && (
                <>
                    <h4>Select an available slot bellow</h4>
                    <div className="date-buttons">
                        { dayTomorrow && <GbTag className="tag" iconName="calendar">NOVEMBER, { dayTomorrow.toString() }</GbTag> }
                        { !dayTomorrow && <div /> }
                        <GbButton className="button">Change date</GbButton>
                    </div>
                    <div className="buttons">
                        <SchedueleButton hours="8:00 - 10:00" handleSelect={() => selectHandler('8:00 - 10:00')} />
                        <SchedueleButton hours="12:00 - 12:30" handleSelect={() => selectHandler('12:00 - 12:30')} />
                        <SchedueleButton hours="14:00 - 15:00" handleSelect={() => selectHandler('14:00 - 15:00')} />
                        <SchedueleButton hours="15:00 - 16:00" handleSelect={() => selectHandler('15:00 - 16:00')} />
                        <SchedueleButton hours="17:00 - 17:30" handleSelect={() => selectHandler('17:00 - 17:30')} />
                    </div>
                </>
            ) }
            { showContactForm && <ContactForm scheduleHours={hours} /> }
        </div>
    );
};
