// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-call */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect } from 'react';
import './App.scss';
import { HashRouter as Router, Route } from 'react-router-dom';
// import OneSignal from 'react-onesignal';
// Views
import { JupiterCall, PostCall } from './views/jupiterCall/react';
import { Call, PostCallAssistent, UserCalling } from './views/userCalling/react';
import { ReceiveCall } from './views/receiveCall/react';
import { CallLink } from './views/callLink/react';
import { GoogleSearch } from './views/userCalling/react/GoogleSearch';
import { VennPage } from './views/userCalling/react/VennPage';
import { Schedule } from './views/userCalling/react/Schedule';
import { ContactForm } from './views/userCalling/react/ContactForm';
import { Business } from './views/business/react';
import { JupiterVideoCall } from './views/jupiterVideoCall/react';
// import AudioToVisual from './views/AudioToVisual';
// // eslint-disable-next-line import/no-cycle
// import { AudioCall } from './views/audioCall/react';
// import { AudioFeedback } from './views/audioFeedback/react';
// import { AudioFeedbackV2 } from './views/audioFeedbackV2/react';
// import { AudioFeedbackV3 } from './views/audioFeedbackV3/react';

// import Home from './views/Home';
// // Components
// import { Nav, NavItem } from './components/nav/react';
// declare let window: any;

function App(): React.ReactElement {
    // window.OneSignal = window.OneSignal || [];
    // const { OneSignal } = window;
    useEffect(() => {
    }, []);

    return (
        <div className="jupiter-scope">
            <div className="portal-overlay" />
            { /* <Nav logo="Project Jupiter">
                <NavItem href="/">Home</NavItem>
                <NavItem href="/audioToVisual">AudioToVisual</NavItem>
                <NavItem href="/videoChat">VideoChat</NavItem>
                <NavItem href="/audioFeedback">AudioFeedback</NavItem>
                <NavItem href="/audioFeedback2">AudioFeedbackV2</NavItem>
                <NavItem href="/audioFeedback3">AudioFeedbackV3</NavItem>
                <NavItem href="/jupiterCall">JupiterCall</NavItem>
            </Nav>

            <section className="content">
                <Router>
                    <Route path="/home" component={Home} />
                    <Route path="/audioToVisual" component={AudioToVisual} />
                    <Route path="/videoChat" component={AudioCall} />
                    <Route path="/audioFeedback" component={AudioFeedback} />
                    <Route path="/audioFeedback2" component={AudioFeedbackV2} />
                    <Route path="/audioFeedback3" component={AudioFeedbackV3} />
                    <Route path="/jupiterCall" component={JupiterCall} />
                </Router>
            </section> */ }
            <Router>
                <Route path="/call" exact component={JupiterCall} />
                <Route path="/video-call" exact component={JupiterVideoCall} />
                <Route path="/business-post-call" exact component={PostCall} />
                <Route path="/user" exact component={UserCalling} />
                <Route path="/user/google-search" exact component={GoogleSearch} />
                <Route path="/user/venn-page" exact component={VennPage} />
                <Route path="/user/call-venn" exact component={Call} />
                <Route path="/user/post-call" exact component={PostCallAssistent} />
                <Route path="/user/post-call/schedule" exact component={Schedule} />
                <Route path="/user/post-call/contact-form" exact component={ContactForm} />
                <Route path="/receive" exact component={ReceiveCall} />
                <Route path="/calllink" exact component={CallLink} />
                <Route path="/business" exact component={Business} />
                <Route path="/manuel-design" exact component={CallLink} />
            </Router>
        </div>
    );
}

export default App;
