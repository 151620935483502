/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line eslint-comments/no-duplicate-disable
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable complexity */
import React, {
    useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import io, { Socket } from 'socket.io-client';
import { useHistory } from 'react-router-dom';
import Peer, { SignalData } from 'simple-peer';
import { CallingVenn, InCall } from '.';
import { SOCKET_IO_SERVER } from './constants';

export const CallLink: React.FC = () => {
    const rootClassName = 'jp-call-link';
    const [startCall, setStartCall] = useState(false);
    const [postCall, setPostCall] = useState(false);
    const [isCalling, setIsCalling] = useState(false);
    const [myStream, setMyStream] = useState<MediaStream |null>(null);

    const [yourID, setYourID] = useState('');
    const [peerDomain, setPeerDomain] = useState<string | undefined>('');
    // const [deviceID, setDeviceID] = useState<string | undefined>('');
    const [domainOnServer, setDomainOnServer] = useState<string>();
    const [users, setUsers] = useState({});
    const [caller, setCaller] = useState('');
    const [callerSignal, setCallerSignal] = useState<SignalData>();
    const [peerStream, setPeerStream] = useState<MediaStream |null>(null);

    const socket = useRef<Socket>();

    const history = useHistory();

    const params = {
        initiator: true,
        trickle: false,
        stream: myStream || undefined,
        reconnectTimer: 100,
        /* config: {
            iceServers: [
                { urls: 'stun:global.stun.twilio.com:3478' },
                {
                    urls: 'stun:stun.l.google.com:19302',
                },
            ],
        }, */
    };

    const hangUplHandler = (): void => {
        history.push('/business-post-call');
    };

    const addStream = (id: string | undefined) => {
        // eslint-disable-next-line no-console
        console.log(peerDomain, domainOnServer, users, caller, callerSignal);
        // if (myStream) {
        //     myStream.getTracks().forEach((track) => {
        //         track.stop();
        //     });
        // }
        // eslint-disable-next-line no-console
        // if (!deviceID) return;
        // ask the user for permissions
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        // eslint-disable-next-line promise/catch-or-return
        // eslint-disable-next-line no-void
        void navigator.mediaDevices.getUserMedia({
            video: false,
            audio: {
                echoCancellation: true,
                deviceId: id ? { exact: id } : undefined,
            },
        // eslint-disable-next-line promise/always-return
        }).then((streamObject) => {
            setMyStream(streamObject);
        });
    };

    function callPeer(domain: string | undefined) {
        if (!myStream) return;
        // myPeerObject.addStream(myStream);
        params.initiator = true;
        // params.trickle = false;
        params.stream = myStream;
        const peer = new Peer(params);
        // const peer = new Peer(params);
        // setMyPeerObject(null);
        // const peer = myPeerObject;
        // eslint-disable-next-line no-console
        if (!peer) return;

        // on creating a new peer the peer will try to signal
        // the peers will establish a connection that its called the handshake
        // one peer will send the signal to the other then
        // the other peer will accept that signal then
        // it will signal its own data back to the caller
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        // setAudioDevices((old) => [...old, { name: device.label, id: device.deviceId }]);
        // const theSenders: any = [];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        // myStream.getTracks().forEach((track) => theSenders.push(peer.addTrack(track, myStream)));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        // myStream.getTracks().forEach((track) => theSenders.push(track));
        // myPeerObject.on('track', (track: MediaStreamTrack, stream: MediaStream) => {
        //     // myPeerObject.addTrack(track);
        //     theSenders.push(track);
        // });
        // setSenders(theSenders);
        // and the hanshake its created
        peer.on('signal', (data: any) => {
            if (!socket.current || startCall) return;
            setIsCalling(true);
            socket.current.emit('callUser', { userToCall: domain, signalData: data, from: yourID });
        });

        // stream its the incomming data from the other peer
        peer.on('stream', (streamObject: React.SetStateAction<MediaStream | null>) => {
            setPeerStream(streamObject);
            // eslint-disable-next-line no-console
            console.log('The Other Stream', streamObject);
        });

        peer.on('close', () => {
            setStartCall(false);
            // setReceivingCall(false);
            peer.removeAllListeners();
            peer.destroy();
            hangUplHandler();
            if (socket.current) {
                socket.current.disconnect();
                hangUplHandler();
            }
        });

        if (!socket.current) return;
        // getting the return signal from the other peer in order to complete the handshake
        socket.current.on('callAccepted', (signal: SignalData) => {
            peer.signal(signal);
            setStartCall(true);
            setIsCalling(false);
            // eslint-disable-next-line no-console
            console.log('myStream', myStream);
        });

        if (!socket.current) return;
        socket.current.on('hangupCall', () => {
            setStartCall(false);
            peer.removeAllListeners();
            peer.destroy();
            hangUplHandler();
            if (socket.current) {
                socket.current.disconnect();
                hangUplHandler();
            }
        });

        // eslint-disable-next-line no-console
        peer.on('error', (err: any) => { console.log('error', err); });
    }

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const startCallHandler = (): void => {
        callPeer('testjupiter1.ncuilab.com');
    };

    useEffect(() => {
        const myDomain = window.location.hostname;
        // force to connect to the root url in the server that triggers the io.on event
        socket.current = io(SOCKET_IO_SERVER, {
            auth: {
                token: myDomain,
            },
        });
        addStream('');

        socket.current.on('yourID', (id: any) => {
            // eslint-disable-next-line no-console
            console.log('myID:', id);
            setYourID(id);
        });
        socket.current.on('allUsers', (usersObject: any) => {
            setUsers(usersObject);
        });

        socket.current.on('otherUser', (domain: string) => {
            // eslint-disable-next-line no-console
            console.log('otherDomain', domain);
            setDomainOnServer(domain);
        });

        // handling the fact that whe are being called
        // notifying that the person is being called
        socket.current.on('hey', (data: any) => {
            // eslint-disable-next-line no-console
            setPeerDomain(data?.from);
            setCaller(data?.from);
            setCallerSignal(data?.signal);
            // eslint-disable-next-line no-console
            console.log('The signal', data?.signal);
        });

        socket.current.on('hangupCall', () => {
            if (socket.current) {
                socket.current.disconnect();
                hangUplHandler();
            }
            setPostCall(true);
            hangUplHandler();
            // window.location.reload();
        });

        socket.current.on('disconnectFromServer', () => {
            if (socket.current) {
                socket.current.disconnect();
            }
        });
    }, []);
    useLayoutEffect(() => { setTimeout(() => { startCallHandler(); }, 1000); });
    // setTimeout(() => { startCallHandler(); }, 1000);
    return (
        <>
            { !startCall
            && !postCall
            && !isCalling
            && (
                <>
                    <div className={rootClassName} />
                </>
            ) }
            { isCalling
            && !startCall
            && !postCall
            && (
                <div className={rootClassName}>
                    <CallingVenn mediaStream={peerStream || null} onHangup={() => hangUplHandler()} />
                </div>
            ) }
            { startCall
            && !postCall
            && !isCalling
            && peerStream
            && (
                <div className={rootClassName}>
                    { peerStream && <InCall mediaStream={peerStream} onHangup={() => hangUplHandler()} /> }
                </div>
            ) }
        </>
    );
};
