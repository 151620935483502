// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable complexity */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/require-await */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-call */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-len */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable unicorn/consistent-function-scoping */
// eslint-disable-next-line no-void
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable promise/always-return */
import React, { useEffect, useRef, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import Peer, { SignalData } from 'simple-peer';
import { useHistory } from 'react-router-dom';
import GbButton from '@nc-gb-sds/button/dist/react';
import {
    HomeCall, ReceivingCall, Calling, InCall,
} from '.';
import { SOCKET_IO_SERVER } from './constants';

export const JupiterCall: React.FC = () => {
    const rootClassName = 'jp-jupiter-call';
    const [isCalling, setIsCalling] = useState(false);
    const [homePage, setHomePage] = useState(true);
    const [myStream, setMyStream] = useState<MediaStream |null>(null);
    const [peerStream, setPeerStream] = useState<MediaStream |null>(null);
    const [myPeerObject] = useState<any>();
    // const [otherPeerObject] = useState<any>(() => new Peer(params));
    const [yourID, setYourID] = useState('');
    const [peerDomain, setPeerDomain] = useState<string | undefined>('');
    const [deviceID, setDeviceID] = useState<string | undefined>('');
    const [domainOnServer, setDomainOnServer] = useState<string>();
    const [users, setUsers] = useState({});
    const [receivingCall, setReceivingCall] = useState(false);
    const [caller, setCaller] = useState('');
    const [callerSignal, setCallerSignal] = useState<SignalData>();
    const [callAccepted, setCallAccepted] = useState(false);
    const [serverOccupied, setServerOccupied] = useState(false);
    const [callingReason] = useState('New business');
    // const [senders, setSenders] = useState<Array<any>>([]);
    const history = useHistory();

    const socket = useRef<Socket>();
    // const initiator = (window.location.hash || '') === '#1';
    // let peer: Peer.Instance;
    // let otherPeer: Peer.Instance;
    const params = {
        initiator: true,
        trickle: false,
        stream: myStream || undefined,
        reconnectTimer: 100,
        /* config: {
            iceServers: [
                { urls: 'stun:global.stun.twilio.com:3478' },
                {
                    urls: 'stun:stun.l.google.com:19302',
                },
            ],
        }, */
    };

    /* const params = {
        initiator: true,
        channelName: '<random string>',
        config: { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }, { urls: 'stun:global.stun.twilio.com:3478?transport=udp' }] },
        stream: myStream || undefined,
        trickle: false,
        allowHalfTrickle: false,
        wrtc: typeof window === 'undefined' ? wrtc : '',
        objectMode: false,
    }; */

    function makeAudioOnlyStreamFromExistingStream(stream: MediaStream) {
        // const audioStream = stream;
        // const videoTracks = audioStream.getVideoTracks();
        // // eslint-disable-next-line no-plusplus
        // for (let i = 0, len = videoTracks.length; i < len; i++) {
        //     audioStream.removeTrack(videoTracks[i]);
        // }
        // eslint-disable-next-line no-console
        // console.log('created audio only stream, original stream tracks:', stream.getTracks());
        // eslint-disable-next-line no-console
        // console.log('created audio only stream, new stream tracks:', audioStream.getTracks());
        // const videoTrack = stream.getVideoTracks()[0];
        // const audioTrack = audioStream.getAudioTracks()[0];
        // // let sender;
        // let sender2;
        // if (!myStream) return;
        // const deviceList = await navigator.mediaDevices.enumerateDevices();
        // deviceList = deviceList.filter(device => device.deviceId === chosenDeviceId)
        // const audioTrack = stream.getTracks()[0];
        setMyStream(stream);
        // if (!senders || senders.length === 0) return;
        // // if (myStream === undefined || !myStream?.getTracks || myStream?.getTracks.length === 0) return;
        // // const senderToFind = senders.find((sender) => sender.kind === 'audio');
        // const senderalho = myStream?.getTracks().find((sender) => sender.kind === 'audio');
        // if (!senderalho) return;
        // senderalho.replaceTrack(audioStream);
        // const audioTrack = deviceList.find((device) => device.deviceId === deviceID);
        // const oldTrack = myStream?.getAudioTracks()[0];
        // // const audioSender: any = myPeerObject.addTrack(audioTrack, myStream);
        // if (!audioTrack) return;1234
        // const old = myStream?.getTracks()[0];
        // if (!old) return;
        // // if (audioTrack === oldTrack) return;
        // if (myPeerObject) {
        //     myPeerObject.removeTrack(old, stream);
        //     // myPeerObject.addTrack(audioTrack, audioStream);
        //     myPeerObject.replaceTrack(old, audioTrack, stream);
        // }
        // if (otherPeerObject) {
        //     otherPeerObject.removeTrack(old, stream);
        //     // otherPeerObject.addTrack(audioTrack, audioStream);
        //     otherPeerObject.replaceTrack(old, audioTrack, stream);
        // }

        // if (myPeerObject) {
        //     // sender = myPeerObject.getSenders().find((s: any) => s.track.kind === videoTrack.kind);
        //     // eslint-disable-next-line prefer-destructuring
        //     sender2 = myPeerObject._senderMap;
        //     // myPeerObject.addTrack(audioTrack, audioStream);
        //     // myPeerObject.replaceTrack(myStream?.getTracks()[0], audioTrack, audioStream);
        // } else {
        //     if (!otherPeerObject) return;
        //     // sender = otherPeerObject.getSenders().find((s: any) => s.track.kind === videoTrack.kind);
        //     sender2 = otherPeerObject.wrtc.getSenders().find((s: any) => s.track.kind === audioTrack.kind);
        //     // otherPeerObject.addTrack(audioTrack, audioStream);
        //     // otherPeerObject.replaceTrack(myStream?.getTracks()[0], audioTrack, audioStream);
        // }

        // // eslint-disable-next-line no-console
        // console.log('found sender:', sender2);
        // // sender.replaceTrack(videoTrack);
        // sender2.replaceTrack(audioTrack);
    }
    const addStream = (id: string | undefined) => {
        // if (myStream) {
        //     myStream.getTracks().forEach((track) => {
        //         track.stop();
        //     });
        // }
        // eslint-disable-next-line no-console
        console.log(deviceID);
        // if (!deviceID) return;
        // ask the user for permissions
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        // eslint-disable-next-line promise/catch-or-return
        // eslint-disable-next-line no-void
        void navigator.mediaDevices.getUserMedia({
            video: false,
            audio: {
                echoCancellation: true,
                deviceId: id ? { exact: id } : undefined,
            },
        }).then((streamObject) => {
            makeAudioOnlyStreamFromExistingStream(streamObject);
        });
    };

    useEffect(() => {
        const myDomain = window.location.hostname;
        // force to connect to the root url in the server that triggers the io.on event
        socket.current = io(SOCKET_IO_SERVER, {
            auth: {
                token: myDomain,
            },
        });
        addStream('');

        socket.current.on('yourID', (id: any) => {
            // eslint-disable-next-line no-console
            console.log('myID:', id);
            setYourID(id);
        });
        socket.current.on('allUsers', (usersObject: any) => {
            setUsers(usersObject);
        });

        socket.current.on('otherUser', (domain: string) => {
            // eslint-disable-next-line no-console
            console.log('otherDomain', domain);
            setDomainOnServer(domain);
        });

        // handling the fact that whe are being called
        // notifying that the person is being called
        socket.current.on('hey', (data: any) => {
            // eslint-disable-next-line no-console
            setPeerDomain(data?.from);
            setCaller(data?.from);
            setReceivingCall(true);
            setHomePage(false);
            setCallerSignal(data?.signal);
            // eslint-disable-next-line no-console
            console.log('The signal', data?.signal);
        });

        socket.current.on('hangupCall', () => {
            setCallAccepted(false);
            setIsCalling(false);
            setReceivingCall(false);
            setHomePage(true);
            history.push({
                pathname: '/business-post-call',
                state: { reason: callingReason },
            });
        });

        socket.current.on('disconnectFromServer', () => {
            setServerOccupied(true);
            if (socket.current) {
                socket.current.disconnect();
                // history.push({
                //     pathname: '/business-post-call',
                //     state: { reason: callingReason },
                // });
                window.location.reload();
            }
        });
    }, []);

    function callPeer(domain: string | undefined) {
        if (!myStream) return;
        // myPeerObject.addStream(myStream);
        params.initiator = true;
        // params.trickle = false;
        params.stream = myStream;
        const peer = new Peer(params);
        // const peer = new Peer(params);
        // setMyPeerObject(null);
        // const peer = myPeerObject;
        // eslint-disable-next-line no-console
        console.log('myPeerObject HERE', myPeerObject);
        if (!peer) return;

        // on creating a new peer the peer will try to signal
        // the peers will establish a connection that its called the handshake
        // one peer will send the signal to the other then
        // the other peer will accept that signal then
        // it will signal its own data back to the caller
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        // setAudioDevices((old) => [...old, { name: device.label, id: device.deviceId }]);
        const theSenders: any = [];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        // myStream.getTracks().forEach((track) => theSenders.push(peer.addTrack(track, myStream)));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        myStream.getTracks().forEach((track) => theSenders.push(track));
        // myPeerObject.on('track', (track: MediaStreamTrack, stream: MediaStream) => {
        //     // myPeerObject.addTrack(track);
        //     theSenders.push(track);
        // });
        // setSenders(theSenders);
        // and the hanshake its created
        peer.on('signal', (data: any) => {
            if (!socket.current || callAccepted) return;
            socket.current.emit('callUser', { userToCall: domain, signalData: data, from: yourID });
        });

        // stream its the incomming data from the other peer
        peer.on('stream', (streamObject: React.SetStateAction<MediaStream | null>) => {
            setPeerStream(streamObject);
            // eslint-disable-next-line no-console
            console.log('The Other Stream', streamObject);
        });

        peer.on('close', () => {
            setCallAccepted(false);
            setIsCalling(false);
            setReceivingCall(false);
            setHomePage(true);
            peer.removeAllListeners();
            peer.destroy();
            history.push({
                pathname: '/business-post-call',
                state: { reason: callingReason },
            });
        });

        if (!socket.current) return;
        // getting the return signal from the other peer in order to complete the handshake
        socket.current.on('callAccepted', (signal: SignalData) => {
            peer.signal(signal);
            setIsCalling(false);
            setCallAccepted(true);
        });

        if (!socket.current) return;
        socket.current.on('hangupCall', () => {
            setCallAccepted(false);
            setIsCalling(false);
            setReceivingCall(false);
            setHomePage(true);
            peer.removeAllListeners();
            peer.destroy();
            // window.location.reload();
            history.push({
                pathname: '/business-post-call',
                state: { reason: callingReason },
            });
        });

        // eslint-disable-next-line no-console
        peer.on('error', (err: any) => { console.log('error', err); });
    }

    function acceptCall() {
        if (!myStream) return;
        setCallAccepted(true);
        setIsCalling(false);
        setReceivingCall(false);
        setHomePage(false);
        params.initiator = false;
        params.stream = myStream;
        const otherPeer = new Peer(params);
        // setMyPeerObject(null);
        // setOtherPeerObject(() => new Peer(params));
        // eslint-disable-next-line no-console
        // console.log('myPeerObject', O);
        const theSenders: any = [];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        myStream.getTracks().forEach((track) => theSenders.push(track));
        // setSenders(theSenders);
        // myStream.getTracks().forEach((track) => otherPeer.addTrack(track, myStream));
        // if (!otherPeerObject) return;
        // sending the other peer signal to the caller peer
        otherPeer.on('signal', (data: any) => {
            if (!socket.current) return;
            socket.current.emit('acceptCall', { signal: data, to: caller });
        });

        otherPeer.on('stream', (streamObject: MediaStream) => {
            setPeerStream(streamObject);
        });

        otherPeer.on('close', () => {
            setCallAccepted(false);
            setIsCalling(false);
            setReceivingCall(false);
            setHomePage(true);
            otherPeer.removeAllListeners();
            otherPeer.destroy();
            // window.location.reload();
            // eslint-disable-next-line no-console
            console.log('CLOSED');
            history.push({
                pathname: '/business-post-call',
                state: { reason: callingReason },
            });
        });

        if (!callerSignal) return;
        otherPeer.signal(callerSignal); // this method infroms that the perr have accepted the other peer signal
    }

    const handleDomainCall = (domain: string | undefined): void => {
        setIsCalling(true);
        setCallAccepted(false);
        setHomePage(false);
        setPeerDomain(domain);
        callPeer(domain);
    };

    const handleCallReceived = (): void => {
        acceptCall();
    };

    const handleCallHangup = (): void => {
        if (!socket.current) return;
        socket.current.emit('hanUpServer', peerDomain);
        setCallAccepted(false);
        setHomePage(true);
        setIsCalling(false);
        setReceivingCall(false);
    };

    const handleCallRejected = (): void => {
        if (!socket.current) return;
        socket.current.emit('hanUpServer', peerDomain);
        setCallAccepted(false);
        setHomePage(true);
        setReceivingCall(false);
        setIsCalling(false);
    };

    // eslint-disable-next-line require-await
    const changeAudioInput = (deviceId: string) => {
        setDeviceID(deviceId);
        // addStream(deviceId);
        // eslint-disable-next-line no-console
        // const pc = myPeerObject

        // let audioTrack = myStream.getVideoTracks()[0];
        // const sender = pc.getSenders().find(function(s) {
        //         return s.track.kind == videoTrack.kind;
        //     });
        // console.log('found sender:', sender);
        // pc.replaceTrack(videoTrack);
        // eslint-disable-next-line no-console
        // console.log('changing MIC Beggining', deviceID);
        // eslint-disable-next-line no-console
        // ask the user for permissions
        // eslint-disable-next-line no-console
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        // eslint-disable-next-line promise/catch-or-return
        // eslint-disable-next-line no-void
        // eslint-disable-next-line no-console
        console.log('changing MIC', deviceID);
    };

    const handleTryAgain = (): void => {
        window.location.reload();
    };

    return (
        <div className={rootClassName}>
            { homePage
            && domainOnServer
            && !serverOccupied
            && (
                <HomeCall
                    domainOnServer={domainOnServer}
                    users={users}
                    onCallingDomain={(domainToCall) => handleDomainCall(domainToCall)} />
            ) }
            { serverOccupied
            && (
                <>
                    <span className="gb-text-h3">SERVER OCCUPIED</span>
                    <GbButton
                        skinModifier="muted"
                        onClick={() => handleTryAgain()}>Try Again
                    </GbButton>
                </>
            ) }
            { receivingCall && <ReceivingCall caller={caller} onHangup={() => handleCallRejected()} onCallReceived={() => handleCallReceived()} /> }
            { isCalling && myStream && <Calling setAudioDestination={changeAudioInput} peerDomain={peerDomain} onHangUpCall={() => handleCallRejected()} myStreamObject={myStream} /> }
            { callAccepted && peerStream && myStream && <InCall setAudioDestination={changeAudioInput} peerDomain={peerDomain} myStreamObject={myStream} streamObject={peerStream} onHangup={() => handleCallHangup()} /> }
        </div>
    );
};
