// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-plusplus */
import React, { useEffect } from 'react';

interface myProps {
    micVolume: number | undefined,
}

export const VolumeMeter:React.FC<myProps> = (props: myProps) => {
    const rootClassName = 'pids-wrapper';

    function colorPids(vol: number | undefined) {
        if (!vol) return;
        const all_pids:Array<HTMLDivElement> = Array.from(document.querySelectorAll(`.${rootClassName}__pid`));
        const amout_of_pids = Math.round(vol / 10);
        if (!all_pids) return;
        const elem_range = all_pids.slice(0, amout_of_pids);
        // eslint-disable-next-line unicorn/no-for-loop
        for (let i = 0; i < all_pids.length; i++) {
            all_pids[i].style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
        }
        // eslint-disable-next-line unicorn/no-for-loop
        for (let i = 0; i < elem_range.length; i++) {
            // console.log(elem_range[i]);
            elem_range[i].style.backgroundColor = '#30D1BE';
        }
    }

    useEffect(() => {
        colorPids(props.micVolume);
    }, [props.micVolume]);

    return (
        <div className={`${rootClassName}`}>
            <div className={`${rootClassName}__pid`} />
            <div className={`${rootClassName}__pid`} />
            <div className={`${rootClassName}__pid`} />
            <div className={`${rootClassName}__pid`} />
            <div className={`${rootClassName}__pid`} />
            <div className={`${rootClassName}__pid`} />
            <div className={`${rootClassName}__pid`} />
            <div className={`${rootClassName}__pid`} />
            <div className={`${rootClassName}__pid`} />
            <div className={`${rootClassName}__pid`} />
        </div>
    );
};
