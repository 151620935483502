import GbButton from '@nc-gb-sds/button/dist/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

export const CallEnded: React.FC = () => {
    const rootClassName = 'jp-all-set';
    const [backToWebsite] = useState(false);
    const history = useHistory();

    // eslint-disable-next-line no-console
    console.log(backToWebsite);
    return (
        <div className={rootClassName}>
            <h4>All set</h4>
            <h6>I’ll get back to you as soon as possible!</h6>
            <div className="end-circle">
                <div className="thumb" />
                <div className="image" />
            </div>
            <GbButton
                className="back"
                onClick={() => history.push('/user/venn-page')}>
                Go back to website
            </GbButton>
        </div>
    );
};
