import React from 'react';

export const Overlay:React.FC<any> = () => {
    const rootClassName = 'jp-overlay';
    return (
        <>
            <div className={rootClassName} />
        </>
    );
};
