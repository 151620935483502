// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-expressions */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-len */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import GbButton from '@nc-gb-sds/button/dist/react';
import GbTag from '@nc-gb-sds/tag/dist/react';
import React, { useEffect, useState } from 'react';
import { AudioSettings } from 'src/views/jupiterCall/react/AudioSettings';

interface myProps {
    onHangup: () => void,
    mediaStream: MediaStream | null
}

export const CallingVenn: React.FC<myProps> = (props: myProps) => {
    const url = '/phonesound.mp3';
    const [audio] = useState(new Audio(url));
    const [volume, setVolume] = useState<number>(50);
    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [something, setSomething] = useState<string>('50');
    const [playing, setPlaying] = useState(true);
    const [isGlowing, setIsGlowing] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsGlowing(!isGlowing);
        }, 800);
    });

    useEffect(() => {
        if (!audio) return;
        if (!volume) return;
        audio.setAttribute('crossorigin', 'anonymous');
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
        audio.setAttribute('crossorigin', 'anonymous');
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);
    // eslint-disable-next-line unicorn/consistent-function-scoping
    useEffect(() => () => audio.pause(), []);

    const rootClassName = 'calling-wrapper';
    // eslint-disable-next-line no-console
    console.log(something, volume);
    // const addStream = (id: string | undefined) => {
    //     // eslint-disable-next-line no-void
    //     void navigator.mediaDevices.getUserMedia({
    //         video: false,
    //         audio: {
    //             echoCancellation: true,
    //             deviceId: id ? { exact: id } : undefined,
    //         },
    //     // eslint-disable-next-line promise/always-return
    //     }).then((stream) => {
    //         setStreamObject(stream);
    //     });
    // };
    const muteMic = (): void => {
        if (!props.mediaStream) return;
        // eslint-disable-next-line no-param-reassign
        props.mediaStream.getAudioTracks()[0].enabled = !(props.mediaStream.getAudioTracks()[0].enabled);
        setIsMuted(true);
    };
    const hangUp = (): void => {
        props.onHangup();
    };
    return (
        <div className={rootClassName}>
            <div className={`${rootClassName}__top`}>
                <div className={`${rootClassName}__top__call`}>You are calling:</div>
                <h4 className={`${rootClassName}__top__name`}>manuel.design</h4>
                <GbTag className={`${rootClassName}__top__tag`}>New business</GbTag>
            </div>
            <div className={`${rootClassName}__venn-circle`}>
                <div className={`${rootClassName}__venn-circle__image `} />
                <div className={`${rootClassName}__venn-circle__inner ${isGlowing ? 'glowing-in' : 'glowing-out'}`} />
                <div className={`${rootClassName}__venn-circle__glow ${isGlowing ? 'glowing-in' : 'glowing-out'}`} />
            </div>
            <div className={`${rootClassName}__footer__controls`}>
                <div className={`${rootClassName}__footer__controls__mic`}>
                    <GbButton
                        size="lg"
                        className={`${rootClassName}__footer__controls__mic__button ${isMuted ? 'muted' : ''}`}
                        skin="ghost"
                        skinModifier="inverted"
                        isIcon
                        iconName="mute"
                        onClick={() => muteMic()}>
                        Button
                    </GbButton>
                </div>
                <div
                    className={`${rootClassName}__footer__controls__phone`}
                    onClick={() => hangUp()}
                    role="button"
                    tabIndex={0}
                    aria-hidden="true">
                    <span className={`${rootClassName}__footer__controls__phone__icon`} />
                </div>
                <div className={`${rootClassName}__footer__controls__sound`}>
                    <AudioSettings changeAudioDestination={(value) => setSomething(value)} changeSpeakersVolume={(value) => setVolume(value as number)} audioStream={props.mediaStream || new MediaStream()} />
                </div>
            </div>
        </div>
    );
};
