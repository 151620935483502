// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-console */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable complexity */
import React, { useState } from 'react';

export const Timer:React.FC = () => {
    const [isActive, setIsActive] = useState(false);
    const [isPaused, setIsPaused] = useState(true);
    const [time, setTime] = useState(0);

    const handleStart = () => {
        setIsActive(true);
        setIsPaused(false);
    };

    React.useEffect(() => {
        handleStart();
        let interval:any = null;

        if (isActive && isPaused === false) {
            interval = setInterval(() => {
                setTime((old) => old + 10);
            }, 10);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isActive, isPaused]);

    // const handlePauseResume = () => {
    //     setIsPaused(!isPaused);
    // };

    // const handleReset = () => {
    //     setIsActive(false);
    //     setTime(0);
    // };

    return (
        <div className="timer">
            <span className="digits">
                { (`0${Math.floor((time / 60000) % 60)}`).slice(-2) }:
            </span>
            <span className="digits">
                { (`0${Math.floor((time / 1000) % 60)}`).slice(-2) }
            </span>
            { /* <span className="digits mili-sec">
                { (`0${(time / 10) % 100}`).slice(-2) }
            </span> */ }
        </div>
    );
};
