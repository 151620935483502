import GbButton from '@nc-gb-sds/button/dist/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const VennPage:React.FC = () => {
    const rootClassName = 'jp-user-calling';
    const history = useHistory();

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const callVennHandler = (): void => {
        history.push('/user/call-venn');
    };
    return (
        <div className={`${rootClassName}__veen-page`}>
            <div className={`${rootClassName}__veen-page__1`}>
                <div className={`${rootClassName}__veen-page__1__button`}>
                    <GbButton
                        iconName="other"
                        onClick={() => callVennHandler()}>
                        Call
                    </GbButton>
                </div>
            </div>
            <div className={`${rootClassName}__veen-page__2`} />
            <div className={`${rootClassName}__veen-page__3`} />
            <div className={`${rootClassName}__veen-page__4`} />
        </div>
    );
};
