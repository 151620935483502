import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { callReducer } from './calls/callReducer';
// ...

const rootReducer = combineReducers({
    callReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
