// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/ban-types */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable complexity */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/require-await */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-plusplus */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line eslint-comments/disable-enable-pair
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable promise/always-return */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import GbPopover from '@nc-gb-sds/popover/dist/react';
import GbButton from '@nc-gb-sds/button/dist/react';
import GbDivider from '@nc-gb-sds/divider/dist/react';
import { GbSelect, GbSelectOption } from '@nc-gb-sds/select/dist/react';
import GbRangeInput, { MultipleValue } from '@nc-gb-sds/range-input/dist/react';
import ReactDOM from 'react-dom';
import { VolumeMeter } from './VolumeMeter';
import { Overlay } from './Overlay';

interface myProps {
    audioStream: MediaStream,
    changeSpeakersVolume: (val: number | MultipleValue) => void,
    changeAudioDestination: (val: string) => void,
}

interface device {
    name: string,
    id: string,
}

export const AudioSettings:React.FC<myProps> = (props: myProps) => {
    const anchorRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpened = (): void => setIsOpen(!isOpen);
    const close = (): void => setIsOpen(false);

    const [microphoneValue, setMicrophoneValue] = useState<device>();
    const [canClose, setCanClose] = useState<boolean>(true);

    const [microphoneVolume, setMicrophoneVolume] = useState<number>(0);

    const [speakersValue, setSpeakersValue] = useState<device>();

    const [speakersVolume, setSpeakersVolume] = useState<number | MultipleValue>(50);

    const rootClassName = 'jupiter-scope';

    const [audioDevices, setAudioDevices] = useState<Array<device>>([]);
    const [speakersDevices, setSpeakersDevices] = useState<Array<device>>([]);
    const handleSpeakersChange = (id: string): void => setSpeakersValue(speakersDevices.find((obj) => obj.id === id));

    // eslint-disable-next-line unicorn/consistent-function-scoping
    // const searchStringInArray = (str: string, strArray: Array<string>): string => {
    //     // eslint-disable-next-line unicorn/no-for-loop
    //     for (let index = 0; index < strArray.length; index++) {
    //         if (new RegExp(str).exec(strArray[index])) return strArray[index];
    //     }
    //     return '';
    // };

    useEffect(() => {
        // eslint-disable-next-line no-void
        void (async ():Promise<void> => {
            let volumeCallback = null;
            let volumeInterval = null;
            let volumePercentage = 0;
            try {
                const MyAudioContext = window.AudioContext || window.webkitAudioContext;
                const audioContext = new MyAudioContext();
                const audioSource = audioContext.createMediaStreamSource(props.audioStream);
                const analyser = audioContext.createAnalyser();
                analyser.fftSize = 512;
                analyser.minDecibels = -127;
                analyser.maxDecibels = 0;
                analyser.smoothingTimeConstant = 0.4;
                audioSource.connect(analyser);
                const volumes = new Uint8Array(analyser.frequencyBinCount);
                volumeCallback = () => {
                    analyser.getByteFrequencyData(volumes);
                    let volumeSum = 0;
                    // eslint-disable-next-line no-restricted-syntax
                    for (const volume of volumes) {
                        volumeSum += volume;
                    }
                    const averageVolume = volumeSum / volumes.length;
                    // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
                    volumePercentage = ((averageVolume * 100) / 127);
                    setMicrophoneVolume(volumePercentage);
                };
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Failed to initialize volume visualizer, simulating instead...', error);
            }

            if (volumeCallback !== null && volumeInterval === null) {
                volumeInterval = setInterval(volumeCallback, 100);
            } else if (volumeInterval !== null) {
                clearInterval(volumeInterval);
                volumeInterval = null;
            }
            // Use
            // startButton.addEventListener('click', () => {
            //   // Updating every 100ms (should be same as CSS transition speed)
            //   if(volumeCallback !== null && volumeInterval === null) {
            //       volumeInterval = setInterval(volumeCallback, 100);
            //   }
            // });
            // stopButton.addEventListener('click', () => {
            //   if(volumeInterval !== null) {
            //     clearInterval(volumeInterval);
            //     volumeInterval = null;
            //   }
            // });
        })();

        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            // eslint-disable-next-line no-console
            console.log('enumerateDevices() not supported.');
            return;
        }
        // List cameras and microphones.
        navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
                devices.forEach((device) => {
                    // eslint-disable-next-line no-console
                    // console.log(`${device.kind}: ${device.label
                    // }`);
                    if (device.kind === 'audioinput') {
                        // audioDevices.push(device.label);
                        setAudioDevices((old) => [...old, { name: device.label, id: device.deviceId }]);
                        // removing repetidos
                        // audioDevices.splice(audioDevices.indexOf(device.label), 1);
                    } else if (device.kind === 'audiooutput') {
                        // speakersDevices.push(device.label);
                        setSpeakersDevices((old) => [...old, { name: device.label, id: device.deviceId }]);
                        // removing repetidos
                        // speakersDevices.splice(speakersDevices.indexOf(device.label), 1);
                    }
                });
            })
            .finally(() => {
                // eslint-disable-next-line no-console
                console.log(audioDevices);
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }, []);

    const handleAudioChange = (id: string) => {
        setMicrophoneValue(audioDevices.find((obj) => obj.id === id));
        props.changeAudioDestination(id);
    };

    const changeSpeakersValue = (val: number | MultipleValue) => {
        setSpeakersVolume(val);
        // eslint-disable-next-line no-console
        // console.log(val);
        props.changeSpeakersVolume(val);
    };

    const defaultAudioDevice = audioDevices.find((obj) => obj.name.includes('Default'));
    const defaultSpeakersDevice = audioDevices.find((obj) => obj.name.includes('Default'));

    const audioDropdown = (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <GbSelect cssScope={rootClassName} onChange={handleAudioChange} onEnterDone={() => { setCanClose(false); }} onLeaveDone={() => { setCanClose(true); }} selectedValue={microphoneValue?.name || defaultAudioDevice?.name || 'Default'}>
            { audioDevices.map((device: device) => <GbSelectOption value={device.id}> { `${device.name} | ${device.id}` } </GbSelectOption>) }
        </GbSelect>
    );
    const speakersDropdown = (
        <GbSelect cssScope={rootClassName} onChange={handleSpeakersChange} onEnterDone={() => { setCanClose(false); }} onLeaveDone={() => { setCanClose(true); }} selectedValue={speakersValue?.name || defaultSpeakersDevice?.name || 'Default'}>
            { speakersDevices.map((device: device) => <GbSelectOption value={device.id}> { device.name } </GbSelectOption>) }
        </GbSelect>
    );

    const domNodeForPortals = document.querySelector('.portal-overlay');

    return (
        <>
            { isOpen && ReactDOM.render(<Overlay />, domNodeForPortals) }
            { !isOpen && domNodeForPortals && ReactDOM.unmountComponentAtNode(domNodeForPortals) }
            <GbButton size="lg" isActive={isOpen} className={`${rootClassName}__button`} skin="ghost" skinModifier="inverted" isIcon iconName="filter" ref={anchorRef} onClick={toggleOpened}>Button</GbButton>
            <GbPopover
                anchorRef={anchorRef}
                isOpened={isOpen}
                onClose={close}
                cssScope={`${rootClassName}`}
                closeOnOutsideClick={canClose}
                position="top">
                <div className={`${rootClassName}__popup`}>
                    <div className={`${rootClassName}__popup__header`}>
                        <div className={`${rootClassName}__popup__header__title`}>Audio Settings</div>
                    </div>
                    <GbDivider className={`${rootClassName}__popup__divider`} />
                    <div className={`${rootClassName}__popup__body`}>
                        <div className={`${rootClassName}__popup__body__microphone`}>
                            <div className={`${rootClassName}__popup__body__microphone__settings`}>
                                <h6 className={`${rootClassName}__popup__body__microphone__settings__title`}>Microphone</h6>
                                <div className={`${rootClassName}__popup__body__microphone__settings__dropdown`}>
                                    { audioDropdown }
                                </div>
                            </div>
                            <div className={`${rootClassName}__popup__body__microphone__feddback`}>
                                <VolumeMeter micVolume={microphoneVolume} />
                            </div>
                        </div>
                        <div className={`${rootClassName}__popup__body__speakers`}>
                            <div className={`${rootClassName}__popup__body__speakers__settings`}>
                                <h6 className={`${rootClassName}__popup__body__speakers__settings__title`}>Speakers</h6>
                                <div className={`${rootClassName}__popup__body__speakers__settings__dropdown`}>
                                    { speakersDropdown }
                                </div>
                            </div>
                            <div className={`${rootClassName}__popup__body__speakers__slider`}>
                                <GbRangeInput value={speakersVolume} onChange={(val: number| MultipleValue): void => changeSpeakersValue(val)} />
                            </div>
                        </div>
                    </div>
                </div>
            </GbPopover>
        </>
    );
};
