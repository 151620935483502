// eslint-disable-next-line no-unused-vars
import { Action } from './actions';

export interface CallState {
    pc: RTCPeerConnection;// manages a peer to peer conection
    localStream: MediaStream; // host of the call
    remoteStream: MediaStream; // other people
}

const servers = { // free google servers
    iceServers: [
        {
            urls: ['stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302'],
        },
    ],
    iceCandidatePoolSize: 10,
};

const initialState = {
    pc: new RTCPeerConnection(servers),
    localStream: new MediaStream(),
    remoteStream: new MediaStream(),
};

export const callReducer = (state: CallState = initialState, action: Action): CallState => {
    switch (action.type) {
        case 'ADD_LOCAL_STREAM': {
            return {
                ...state,
                localStream: action.payload,
            };
        }
        case 'ADD_REMOTE_STREAM': {
            return {
                ...state,
                remoteStream: action.payload,
            };
        }
        case 'CLOSE_CONNECTIONS': {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
};
