// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/no-static-element-interactions */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import GbLoader from '@nc-gb-sds/loader/dist/react';

interface myProps {
    handleSelect: () => void,
    hours: string
}

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value + 1); // update the state to force render
}

export const SchedueleButton: React.FC<myProps> = (props: myProps) => {
    const rootClassName = 'schedule-button';
    const [isSelected, setIsSelected] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const forceUpdate = useForceUpdate();

    useEffect(() => {
        forceUpdate();
    }, []);

    const selectHandler = () => {
        setShowLoader(true);
        setTimeout(() => {
            props.handleSelect();
        }, 2000);
    };

    return (
        <div className={rootClassName}>
            <div className={`${isSelected ? 'selected' : ''} hour`} onClick={() => setIsSelected(!isSelected)}>
                { props.hours }
            </div>
            { isSelected
                && (
                    <div className={`${isSelected ? 'selected' : ''} select`} onClick={() => selectHandler()}>
                        { !showLoader && <span>Select</span> }
                        { showLoader && <GbLoader size="md" /> }
                    </div>
                ) }
        </div>
    );
};
