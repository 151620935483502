import React from 'react';
import ReactDOM from 'react-dom';
// import firebase from 'firebase';
import { Provider } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import App from './App';
import { store } from './store/config';

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>,
    document.querySelector('#root'),
);

// firebase configuration
// const firebaseConfig = {
//     apiKey: 'AIzaSyDrWxMQoKju-o1_rQbAQZ8bP-KShWw_IYE',
//     authDomain: 'jupiter-video-call.firebaseapp.com',
//     databaseURL: 'https://jupiter-video-call-default-rtdb.firebaseio.com',
//     projectId: 'jupiter-video-call',
//     storageBucket: 'jupiter-video-call.appspot.com',
//     messagingSenderId: '869940768337',
//     appId: '1:869940768337:web:f621913797064e8ebe9270',
//     measurementId: 'G-PRB5LPBWFJ',
// };

// if (firebase.apps.length === undefined || firebase.apps.length === 0) {
//     firebase.initializeApp(firebaseConfig);
// }

// export const firestore = firebase.firestore();
