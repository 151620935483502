// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-len */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-console */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-plusplus */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-duplicates */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-param-reassign */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/restrict-plus-operands */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-call */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/no-static-element-interactions */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/click-events-have-key-events */
import GbButton from '@nc-gb-sds/button/dist/react';
import React, { useEffect, useState } from 'react';
import { CallEnded } from 'src/views/jupiterCall/react/CallEnded';
import GbSwitch from '@nc-gb-sds/switch/dist/react';
import { GbSelect, GbSelectOption } from '@nc-gb-sds/select/dist/react';
import GbTextfield from '@nc-gb-sds/textfield/dist/react';

export const PopOver:React.FC = () => {
    const rootClassName = 'jp-pop-over';
    const [disabled, setDisabled] = useState(true);
    const [callEnded, setCallEnded] = useState(false);
    const [checked, setChecked] = useState(false);
    const [selectedValue, setSelectedValue] = useState<string>();

    const handleChange = () => {
        setChecked(!checked);
    };

    useEffect(() => {
        const allInputs = document.querySelectorAll('input');
        console.log(allInputs);
        for (const input of allInputs) {
            input.addEventListener('input', () => {
                setDisabled(false);
            });
        }
    });

    const toggleSection = (e: any) => {
        const allSections = document.querySelectorAll(`.${rootClassName}__body__section__content`);
        [].forEach.call(allSections, (el: any) => {
            if (el.previousElementSibling === e.target) return; // dont remove own active
            el.classList.remove('active');
            el.style.height = null;
        });
        if (e.target.nextElementSibling?.classList[0] === `${rootClassName}__body__section__content`) {
            e.target.nextElementSibling.classList.toggle('active');
            if (e.target.nextElementSibling.style.height) {
                // this is if the accordion is open
                e.target.nextElementSibling.style.height = null;
            } else {
                // if the accordion is currently closed
                e.target.nextElementSibling.style.height = `${e.target.nextElementSibling.scrollHeight + 32}px`;
            }
        } else { // clicked on a child div need to go to parent
            e.target.parentElement.nextElementSibling.classList.toggle('active');
            if (e.target.parentElement.nextElementSibling.style.height) {
                // this is if the accordion is open
                e.target.parentElement.nextElementSibling.style.height = null;
            } else {
                // if the accordion is currently closed
                e.target.parentElement.nextElementSibling.style.height = `${e.target.parentElement.nextElementSibling.scrollHeight + 32}px`;
            }
        }

        // add active class to chevron as well
        const allChevrons = document.querySelectorAll(`.${rootClassName}__body__section__header__chevron`);
        [].forEach.call(allChevrons, (el: any) => {
            if (el.parentElement === e.target) return; // dont remove own active
            el.classList.remove('active');
        });
        if (e.target.nextElementSibling?.classList[0] === `${rootClassName}__body__section__content`) {
            // clicked on the header div need to go to child chevron
            const chevronDiv = e.target.querySelector(`.${rootClassName}__body__section__header__chevron`);
            chevronDiv.classList.toggle('active');
        } else { // clicked on a child div need to go to parent
            const chevronDiv = e.target.parentElement.querySelector(`.${rootClassName}__body__section__header__chevron`);
            chevronDiv.classList.toggle('active');
        }
    };

    return (
        <>
            { !callEnded && (
                <div className={rootClassName}>
                    <h4 className={`${rootClassName}__title`}>Contact Information</h4>
                    <div className={`${rootClassName}__body`}>
                        <div className={`${rootClassName}__body__section`}>
                            <div className={`${rootClassName}__body__section__header`} onClick={(e) => { toggleSection(e); }}>
                                <span className={`${rootClassName}__body__section__header__title`}><span className={`${rootClassName}__body__section__header__title__icon name`} />Add Name</span>
                                <span className={`${rootClassName}__body__section__header__chevron`} />
                            </div>
                            <div className={`${rootClassName}__body__section__content`}>
                                <input
                                    placeholder="Name here"
                                    type="text"
                                    className={`${rootClassName}__body__section__content__input name my-input`} />
                            </div>
                        </div>
                        <div className={`${rootClassName}__body__section`}>
                            <div className={`${rootClassName}__body__section__header`} onClick={(e) => { toggleSection(e); }}>
                                <span className={`${rootClassName}__body__section__header__title`}><span className={`${rootClassName}__body__section__header__title__icon tag`} />Add Tag</span>
                                <span className={`${rootClassName}__body__section__header__chevron`} />
                            </div>
                            <div className={`${rootClassName}__body__section__content`}>
                                <input
                                    placeholder="Tag here"
                                    type="text"
                                    className={`${rootClassName}__body__section__content__input tag my-input`} />
                            </div>
                        </div>
                        <div className={`${rootClassName}__body__section`}>
                            <div className={`${rootClassName}__body__section__header`} onClick={(e) => { toggleSection(e); }}>
                                <span className={`${rootClassName}__body__section__header__title`}><span className={`${rootClassName}__body__section__header__title__icon reminder`} />Add Reminder</span>
                                <span className={`${rootClassName}__body__section__header__chevron`} />
                            </div>
                            <div className={`${rootClassName}__body__section__content reminder`}>
                                <div className={`${rootClassName}__body__section__content__label`}>Reminder</div>
                                <input
                                    placeholder="Write Reminder here"
                                    type="text"
                                    className={`${rootClassName}__body__section__content__input reminder my-input`} />
                                <div className={`${rootClassName}__body__section__content__section`}>
                                    <div className={`${rootClassName}__body__section__content__section__input date`}>
                                        <div className={`${rootClassName}__body__section__content__section__input__label`}>Pick date</div>
                                        <input
                                            placeholder="D/M/Y"
                                            type="text"
                                            className={`${rootClassName}__body__section__content__section__input my-input`} />
                                    </div>
                                    <div className={`${rootClassName}__body__section__content__section__input hour`}>
                                        <div className={`${rootClassName}__body__section__content__section__input__label`}>Pick hour</div>
                                        <input
                                            placeholder="H:M"
                                            type="text"
                                            className={`${rootClassName}__body__section__content__input my-input`} />
                                    </div>
                                </div>
                                <div className={`${rootClassName}__body__section__content__section`}>
                                    <div className={`${rootClassName}__body__section__content__section__checkbox checkbox`}>
                                        <div className={`${rootClassName}__body__section__content__section__label`}>Recurring</div>
                                        <GbSwitch onChange={handleChange} isChecked={checked} />
                                    </div>
                                    <div className={`${rootClassName}__body__section__content__section__drop drop`}>
                                        <GbSelect
                                            cssScope="jupiter-scope"
                                            onChange={(value: string): void => setSelectedValue(value)}
                                            selectedValue={selectedValue}
                                            size="sm"
                                            isInverted
                                            block>
                                            <GbSelectOption value="Never">Never</GbSelectOption>
                                            <GbSelectOption value="Monthly">Monthly</GbSelectOption>
                                            <GbSelectOption value="Weekly">Weekly</GbSelectOption>
                                        </GbSelect>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${rootClassName}__body__section`}>
                            <div className={`${rootClassName}__body__section__header`} onClick={(e) => { toggleSection(e); }}>
                                <span className={`${rootClassName}__body__section__header__title`}><span className={`${rootClassName}__body__section__header__title__icon note`} />Add Note</span>
                                <span className={`${rootClassName}__body__section__header__chevron`} />
                            </div>
                            <div className={`${rootClassName}__body__section__content`}>
                                <GbTextfield
                                    placeholder="Note here"
                                    aria-label="Base multi-line"
                                    isMultiline
                                    isInverted />
                            </div>
                        </div>
                    </div>
                    <div className={`${rootClassName}__buttons`}>
                        <GbButton>
                            Cancel
                        </GbButton>
                        <GbButton
                            className={`next ${disabled ? 'disabled' : ''}`}
                            onClick={() => { setCallEnded(true); }}>
                            Next
                        </GbButton>
                    </div>
                </div>
            ) }
            { callEnded
        && (<CallEnded />) }
        </>
    );
};
