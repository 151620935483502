// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import GbButton from '@nc-gb-sds/button/dist/react';
import GbTextfield from '@nc-gb-sds/textfield/dist/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

interface myProps {
    location: any | null
}

export const GoogleSearch:React.FC<myProps> = (props: myProps) => {
    const rootClassName = 'jp-user-calling';
    const [searchValue, setSearchValue] = useState<any | null>(props.location?.state?.value ? props.location?.state.value : ' ');
    const history = useHistory();

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const callVennHandler = (): void => {
        history.push('/user/call-venn');
    };

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const goToVennHandler = (): void => {
        history.push('/user/venn-page');
    };

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const searchFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setSearchValue(value);
    };

    return (
        <div className={`${rootClassName}__searched`}>
            <div className={`${rootClassName}__searched__field-wrapper`}>
                <div className={`${rootClassName}__searched__field-wrapper__input`}>
                    <GbTextfield
                        aria-label="Search Input"
                        placeholder="Search or type URL"
                        onChange={searchFieldChangeHandler}
                        value={searchValue} />
                </div>
            </div>
            <div className={`${rootClassName}__searched__form`}>
                <div className={`${rootClassName}__searched__form__button1`}>
                    <GbButton
                        onClick={() => callVennHandler()} />
                </div>
                <div className={`${rootClassName}__searched__form__button2`}>
                    <GbButton
                        onClick={() => goToVennHandler()} />
                </div>
            </div>
            <div className={`${rootClassName}__searched__footer`} />
        </div>
    );
};
