// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-plusplus */
import React, { useEffect, useRef, useState } from 'react';

interface myProps {
    streamObject: MediaStream,
    gainVolume: number,
    className?: string | null
}

declare global {
    interface Window {
        webkitAudioContext: typeof AudioContext
    }
}

export const AudioVisualizer: React.FC<myProps> = (props: myProps) => {
    const rootClassName = 'jp-visualizer';
    const videoElement = useRef<HTMLVideoElement>(null);
    // const stream = props.streamObject;
    // // const [audioValue] = useState<MediaStream>(stream);
    // const [audioData, setAudioData] = useState<Uint8Array>();
    // const [dataArray, setDataArray] = useState<Uint8Array>();
    // const [analyser, setAnalyser] = useState<AnalyserNode>();
    // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    // // const [rafId, setRafId] = useState<number | undefined>();
    // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    // // const [tick, setTick] = useState<any>();
    const [isTalking, setIsTalking] = useState<boolean>(true);
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const [audioContext] = useState<AudioContext>(new AudioContext());
    const [audioSource] = useState<MediaStreamAudioSourceNode>(audioContext.createMediaStreamSource(props.streamObject));
    const [audioGain1] = useState<GainNode>(audioContext.createGain());
    const [audioChannelSplitter] = useState<ChannelSplitterNode>(audioContext.createChannelSplitter(audioSource.channelCount));

    // const [notTalkingCounter, setNotTalkingCounter] = useState<number>(0);

    const audioVisualizerDiv = useRef<HTMLDivElement>(null);

    // eslint-disable-next-line complexity
    // eslint-disable-next-line unicorn/consistent-function-scoping
    // eslint-disable-next-line complexity
    const draw = (audioIntensity: number | undefined): void => {
        const audioVisualiserElement = audioVisualizerDiv.current;
        // eslint-disable-next-line no-console
        // console.log('Drwawing', audioIntensity);
        // this.isTalking = true;
        // this.isLoud = true;
        // audioData.length;
        // HERE
        let boxShadowValue = '';

        if (!audioVisualiserElement || !audioIntensity) {
            if (audioVisualiserElement) audioVisualiserElement.style.boxShadow = '';
            return;
        }
        // eslint-disable-next-line no-console
        // console.log('drawing with intensity', audioIntensity);
        if (audioIntensity <= 0.5) {
            // setNotTalkingCounter(notTalkingCounter + 1);
            // setNotTalkingCounter(0);
            audioVisualiserElement.style.boxShadow = '';
            return;
        }
        if (audioIntensity <= 0.5 || !audioVisualiserElement) return;
        setIsTalking(true);
        if (audioIntensity > 0.5) {
            boxShadowValue = `
            0 0 ${audioIntensity * 250}px ${audioIntensity * 50}px rgba(225,225,225, ${audioIntensity})
            `;
            audioVisualiserElement.style.boxShadow = boxShadowValue;
        }
    };

    const audioAnalyser: AnalyserNode[] = [];
    const freqs: any = [];
    const audioLevels = [0];
    let notTalkingCounter = 0;

    const calculateAudioLevels = () => {
        // setTimeout(() => {
        // eslint-disable-next-line unicorn/no-for-loop
        for (let channelI = 0; channelI < audioAnalyser.length; channelI++) {
            audioAnalyser[channelI].getByteFrequencyData(freqs[channelI]);
            let value = 0;
            for (let freqBinI = 0; freqBinI < audioAnalyser[channelI].frequencyBinCount; freqBinI++) {
                value = Math.max(value, freqs[channelI][freqBinI]);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                // value = freqs[channelI][freqBinI];
            }
            audioLevels[channelI] = value / 200;
            // audioLevels[channelI] = value * 100;
        }
        if (audioLevels[0] <= 0.5) {
            notTalkingCounter += 1;
            if (notTalkingCounter >= 10) {
                notTalkingCounter = 0;
                setIsTalking(false);
                draw(0);
            }
            draw(audioLevels[0]);
            // setNotTalkingCounter(notTalkingCounter + 1);
            // setNotTalkingCounter(0);
        } else {
            draw(audioLevels[0]);
        }
        requestAnimationFrame(calculateAudioLevels);
        // requestAnimationFrame(calculateAudioLevels);
        // }, 1000 / 60); // Max 15fps — not more needed
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            calculateAudioLevels();
        }, 1000 / 60);
        return () => clearTimeout(timer);
    }, []);

    // eslint-disable-next-line complexity
    // const getAudioIntensity = ():void => {
    //     // eslint-disable-next-line no-console
    //     console.log('getAudioIntensity');
    //     let intensityFromData;
    //     if (!audioData) return;
    //     // eslint-disable-next-line no-restricted-syntax
    //     // const tracks = audioData.getAudioTracks();
    //     // eslint-disable-next-line no-restricted-syntax
    //     for (const item of audioData) {
    //         intensityFromData = (item * 100) / 1200; // change 1200 value if you wish the circle to be resized
    //     }
    //     if (intensityFromData && intensityFromData > 10 && intensityFromData < 11) intensityFromData = 0;
    //     draw(intensityFromData);
    // };

    // const tickFunction = (): void => {
    //     if (!dataArray) return;
    //     // eslint-disable-next-line no-console
    //     analyser?.getByteTimeDomainData(dataArray);
    //     setAudioData(dataArray);
    //     // eslint-disable-next-line @typescript-eslint/unbound-method
    //     // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    //     // setRafId(tick);
    //     getAudioIntensity();
    // };

    // eslint-disable-next-line complexity
    // useEffect(() => {
    //     if (videoElement.current) {
    //         // videoElement.current.muted = true;
    //         const audioContext = new (AudioContext || window.webkitAudioContext)();
    //         setAnalyser(audioContext.createAnalyser());
    //         // const source = audioContext.createMediaStreamSource(stream);
    //         // const gainNode = audioContext.createGain();
    //         // gainNode.gain.value = 0.5;
    //         // videoElement.current.srcObject = stream;
    //         // if (!analyser) return;
    //         // source.connect(gainNode);
    //         // source.connect(audioContext.destination);
    //         const audio = new Audio();
    //         audio.srcObject = stream;
    //         const gainNode = audioContext.createGain();
    //         gainNode.gain.value = 0.5;
    //         if (!audioContext) return;
    //         const source = audioContext.createMediaStreamSource(stream);
    //         // eslint-disable-next-line @typescript-eslint/no-floating-promises
    //         audio.play();
    //         audio.muted = true;
    //         if (!analyser) return;
    //         source.connect(gainNode);
    //         // source.connect(analyser);
    //         setDataArray(new Uint8Array(analyser.frequencyBinCount));
    //         // if (!dataArray) return;
    //         // analyser?.getByteTimeDomainData(dataArray);
    //         // // eslint-disable-next-line @typescript-eslint/unbound-method
    //         // // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    //         // analyser?.getByteTimeDomainData(dataArray);
    //         setAudioData(dataArray);
    //         // eslint-disable-next-line @typescript-eslint/unbound-method
    //         // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    //         // setRafId(tick);
    //         getAudioIntensity();
    //     }
    // });
    // setRafId(requestAnimationFrame(tick));

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(isTalking, props.className, props.gainVolume);
        audioSource.connect(audioGain1);
        audioGain1.connect(audioChannelSplitter);
        // audioGain1.connect(audioContext.destination);

        for (let i = 0; i < audioSource.channelCount; i++) {
            audioAnalyser[i] = audioContext.createAnalyser();
            audioAnalyser[i].minDecibels = -100;
            audioAnalyser[i].maxDecibels = 0;
            audioAnalyser[i].smoothingTimeConstant = 0.8;
            audioAnalyser[i].fftSize = 32;
            freqs[i] = new Uint8Array(audioAnalyser[i].frequencyBinCount);

            audioChannelSplitter.connect(audioAnalyser[i], i, 0);
        }
        if (videoElement.current && videoElement.current) {
            videoElement.current.srcObject = null;
            try {
                videoElement.current.srcObject = props.streamObject;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log('error');
            }
            // videoElement.current.srcObject = props.streamObject;
            // videoElement.current.muted = true;
            // videoElement.current.volume = 0;
        }
    }, [audioSource]);
    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(isTalking, props.className);
        // eslint-disable-next-line no-console
        // console.log('changingGainVolume', props.gainVolume / 100);
        if (!videoElement.current) return;
        audioGain1.gain.value = props.gainVolume / 100;
        // videoElement.current.muted = true;
        videoElement.current.volume = props.gainVolume / 100;
    }, [props.gainVolume]);

    return (
        <>
            { /* <div className={`${rootClassName}__image ${props.className || ''}`} />
            <div className={`${rootClassName}__content`} ref={audioVisualizerDiv} />
            <div className={`${rootClassName}__talking-feedback ${isTalking ? 'is-talking' : ''}`} /> */ }
            <video className={`${rootClassName}__video-show`} playsInline ref={videoElement} autoPlay>
                <track kind="captions" />
            </video>
        </>
    );
};
export default AudioVisualizer;
