// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable promise/always-return */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-call */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable promise/catch-or-return */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable unicorn/consistent-function-scoping */
import GbButton from '@nc-gb-sds/button/dist/react';
import React, { useState } from 'react';
import { CallEnded } from 'src/views/userCalling/react/CallEnded';
import emailjskeys from 'src/emailkey';
import emailjs from 'emailjs-com';
import { useHistory } from 'react-router-dom';

emailjs.init(emailjskeys.USER_ID);

interface myProps {
    scheduleHours: string
}

export const ContactForm:React.FC<myProps> = (props: myProps) => {
    const rootClassName = 'jp-contact-form';
    const [callEnded, setCallEnded] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const history = useHistory();

    const emailFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setEmail(value);
    };

    const phoneFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setPhone(value);
    };

    const handleSubmit = (e: any): void => {
        const mailParams = {
            data: phone || email,
            schedule: props.scheduleHours,
        };
        e.preventDefault(); // Prevents default refresh by the browser
        emailjs.send('service_zgd386g', emailjskeys.TEMPLATE_ID, mailParams)
            .then((response) => {
                // eslint-disable-next-line no-console
                console.log('Message Sent, We will get back to you shortly', response.text);
                setCallEnded(true);
            },
            (error) => {
                // eslint-disable-next-line no-console
                console.log('An error occurred, Please try again', error.text);
                setCallEnded(true);
            });
    };

    return (
        <>
            { !callEnded
        && (
            <div className={rootClassName}>
                <h4>Leave us a contact</h4>
                <div className={`${rootClassName}__inputs`}>
                    <input
                        id="mail"
                        type="text"
                        placeholder="Email here"
                        onChange={emailFieldChangeHandler}
                        value={email} />
                    or
                    <input
                        id="phone"
                        type="text"
                        placeholder="Phone number here"
                        onChange={phoneFieldChangeHandler}
                        value={phone} />
                </div>
                <div className={`${rootClassName}__buttons`}>
                    <GbButton
                        onClick={(e) => handleSubmit(e)}>
                        Send
                    </GbButton>
                    <GbButton
                        className="black"
                        onClick={() => history.push('/user/call-venn')}>
                        Call Again
                    </GbButton>
                </div>
            </div>
        ) }
            { callEnded && <CallEnded /> }
        </>
    );
};
